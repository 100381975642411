<template>
	<div class="order-list">
		
		<div class="bind-item">
			<h1>图片设置</h1>
			<el-form class="logoUpload" ref="form" label-width="80px">
				<div  class="banner-upload">
					<h3>LOGO</h3>
					<div class="logoImg">
						<img v-show="configMap.logoFileUrl" :src="configMap.logoFileUrl" alt="Selected Image" >
						<img v-show="!configMap.logoFileUrl" :src="require('../../assets/img/logo.png')" alt="Selected Image">
					</div>
					<input type="file" ref="fileInput" style="display: none" @change="handleFileChange" accept="image/*">
					<el-button type="primary" @click="openFileInput">选择图片</el-button>
					<el-button type="success" @click="uploadLogo">上传</el-button>
				</div>
				<div class="banner-upload">
					<h3>banner1</h3>
					<div class="logoImgBanner">
						<img v-show="configMap.banner.banner1Url" :src="configMap.banner.banner1Url">
					</div>
					<div class="banner-button">
						<input type="file" ref="bannerInput1" style="display: none" @change="handleBannerChange(1, $event)" accept="image/*">
						<el-button type="primary" @click="bannerOpenFileInput(1)">选择图片</el-button>
						<el-button type="success" @click="uploadBanner(1)">上传</el-button>
					</div>
				</div>
				<div class="banner-upload">
					<h3>banner2</h3>
					<div class="logoImgBanner">
						<img v-show="configMap.banner.banner2Url" :src="configMap.banner.banner2Url">
					</div>
					<div class="banner-button">
						<input type="file" ref="bannerInput2" style="display: none" @change="handleBannerChange(2, $event)" accept="image/*">
						<el-button type="primary" @click="bannerOpenFileInput(2)">选择图片</el-button>
						<el-button type="success" @click="uploadBanner(2)">上传</el-button>
					</div>
				</div>
				<div class="banner-upload">
					<h3>banner3</h3>
					<div class="logoImgBanner">
						<img v-show="configMap.banner.banner3Url" :src="configMap.banner.banner3Url">
					</div>
					<div class="banner-button">
						<input type="file" ref="bannerInput3" style="display: none" @change="handleBannerChange(3, $event)" accept="image/*">
						<el-button type="primary" @click="bannerOpenFileInput(3)">选择图片</el-button>
						<el-button type="success" @click="uploadBanner(3)">上传</el-button>
					</div>
				</div>
			</el-form>
		</div>
		
		<div class="bind-item">
			<h1>登录页文案</h1>
			<el-form ref="form" label-width="80px" style="margin-right: 1rem;">
				<el-form-item label="账户">
					<el-input v-model="configMap.loginTitle" type="textarea" placeholder="登录页的文字描述"></el-input>
				</el-form-item>
				<div  class="bind-sure" >
					<el-button type="primary" @click="setConfig('loginTitle', configMap.loginTitle, 'loginTitle')">设置</el-button>
				</div>
			</el-form>
		</div>
		
	</div>
</template>

<script>

export default {
	data() {
		return {
			
			configList:{},
			
			configMap:{
				gpt: {
					key: '',
					model: 'gpt-3.5-turbo',
					host: 'https://api.openai-proxy.com'
				},
				StabilityAi:{
					key: ""
				},
				officialWebUrl: '',
				officialWebKey: '',
				
				logoFile: null,  // 上传使用
				logoFileUrl: null,  // 显示使用
				banner:{
					banner1File: null,
					banner2File: null,
					banner3File: null,
					banner1Url: null,
					banner2Url: null,
					banner3Url: null,
				},
				loginTitle:"",
				projectName:"",
				contact:'',
				documentUrl:"",
				coinPrice:'',
				titlesPrice:"",
				articlePrice:'',
				picturePrice:'',
				saasKey:'',
				saasDomain:'',
				
				pay:{
					wechatPay:{
						mchid:"",
						appid:"",
						apiKey:"",
						apiclient_key:"",
						apiclient_cert:""
					}
				}
			},
		};
	},
	
	watch: {
		// 监听图片的变化
		chargeRate(chargeRate) {
			// 只能是整数
			this.chargeRate = chargeRate.replace(/[^\d]/g, '')
		},
		articlePrice(articlePrice) {
			// 只能是整数
			this.articlePrice = articlePrice.replace(/[^\d]/g, '')
		},
		picturePrice(picturePrice) {
			// 只能是整数
			this.picturePrice = picturePrice.replace(/[^\d]/g, '')
		}
	},
	
	mounted() {
		this.getConfig()
	},
	
	methods: {
		openFileInput() {
			this.$refs.fileInput.click();  // 打开文件选择框
		},
		bannerOpenFileInput(index) {
			this.$refs['bannerInput'+index].click();  // 打开文件选择框
		},
		handleFileChange(event) {
			this.configMap.logoFile = event.target.files[0];  // 保存选择的文件对象
			this.configMap.logoFileUrl = URL.createObjectURL(this.configMap.logoFile);  // 生成选择的图片的 URL
		},
		handleBannerChange(index, event) {
			console.log(index, this.configMap.banner)
			this.configMap.banner['banner'+index+'File'] = event.target.files[0];  // 保存选择的文件对象
			this.configMap.banner['banner'+index+'Url'] = URL.createObjectURL(this.configMap.banner['banner'+index+'File']);  // 生成选择的图片的 URL
		},
		uploadBanner(index) {
			console.log(this.configMap.banner)
			if (this.configMap.banner['banner'+index+'File']) {
				const formData = new FormData();
				formData.append('file', this.configMap.banner['banner'+index+'File']);  // 将文件对象添加到 FormData
				
				// 发送 POST 请求上传文件
				this.$http.post('/common/upload', formData)
					.then(response => {
						this.logoUrl = response.url
						this.$http.post('/admin/common/setConfig', {
							key: 'banner.banner'+index+'Url',
							value: this.logoUrl,
							name: '登录页banner'+index
						}).then(res => {
							if (res.code === 0) {
								this.$message({
									message: "成功",
									type: "success"
								});
							}
						})
					})
			}
		},
		uploadLogo() {
			if (this.configMap.logoFile) {
				const formData = new FormData();
				formData.append('file', this.configMap.logoFile);  // 将文件对象添加到 FormData
				
				// 发送 POST 请求上传文件
				this.$http.post('/common/upload', formData)
					.then(response => {
						this.logoUrl = response.url
						this.$http.post('/admin/common/setConfig', {
							key: 'logo',
							value: this.logoUrl,
							name: 'Logo'
						}).then(res => {
							if (res.code === 0) {
								this.$message({
									message: "成功",
									type: "success"
								});
							}
						})
					})
			}
		},
		getConfig() {
			console.log(this.configMap, 111)
			return this.$http.post('/admin/common/getConfig', {}).then(res => {
				this.configList = res.data.list ? res.data.list : []
				if (res.data.map === null) {
					return
				}
				Object.keys(res.data.map).forEach((key) => {
					
					if (res.data.map[key] instanceof Object) {
						let prefix = ""
						if (key === 'banner') {
							prefix = process.env.VUE_APP_BASE_API
						}
						try {
							Object.keys(res.data.map[key]).forEach((subKey) => {
								this.configMap[key][subKey] = res.data.map[key][subKey]
							})
						} catch (e) {
							console.log(e)
						}
					} else {
						
						if (key === 'logo') {
							this.configMap['logoFileUrl'] = process.env.VUE_APP_BASE_API + res.data.map[key]
						}
						this.configMap[key] = res.data.map[key]
					}
				})
			})
		},
		
		setConfig(key, value, name) {
			if (!key || !value || !name) {
				this.$message.error('输入错误');
				return;
			}
			return this.$http.post('/admin/common/setConfig', {
				key: key,
				value: value,
				name: name
			}).then(res => {
				if (res.code === 0) {
					this.$message({
						message: "设置"+name+"成功",
						type: "success",
					});
				}
			})
		},
	},
};
</script>
<style lang="scss" scoped>
.bind-item {
	margin: 2rem 1rem;
	padding:1rem;
	border: solid 1px #eee;
	border-radius:1rem;
	h1 {
		margin: 1rem;
		text-align: center;
	}
	.bind-sure {
		display: flex;
		justify-content: center;
	}
	::v-deep .el-textarea__inner {
		height: 5rem !important;
	}
}
::v-deep .el-form-item__label {
	padding-right: 15px !important;
}
.logoUpload {
	display: flex;
	justify-content: center;
}
.logoImg {
	display: flex;
	justify-content: center;
	margin:1rem auto 2rem auto;
	border: solid 1px #eee;
	border-radius: 50%;
	max-width: 100px;
	text-align: center;
	img {
		max-width: 100%;
	}
}
.logoImgBanner {
	max-width: 100px;
}
.banner-upload {
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin:1rem auto 2rem auto;
	border-radius: 50%;
	max-width: 100px;
	text-align: center;
	button {
		height: 2rem;
	}
	img {
		max-width: 100%;
	}
	.banner-button {
		display: flex;
		justify-content: center;
		align-content: center;
	}
	h3 {
		margin-bottom: 2rem;
	}
}
</style>
